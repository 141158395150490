<script>
import { sync } from 'vuex-pathify'

export default {
	data() {
		return {
			showCookiesBanner: false,
			cookiesOptions: [
				{
					title: 'Cookies esenciales',
					description:
						'Sirven para reconocerte cuando ingresás, guardar tus preferencias de configuración y proteger tu cuenta. No pueden deshabilitarse porque son necesarias para el funcionamiento de nuestro sitio.',
					key: 'essential',
				},
				{
					title: 'Datos de usuario de publicidad',
					description:
						'Estas cookies nos ayudan a recopilar datos sobre tu interacción con los anuncios para mejorar la personalización y la relevancia de los mismos',
					value: true,
					key: 'ad_user_data',
				},
				{
					title: 'Personalización de anuncios',
					description:
						'Estas cookies permiten personalizar los anuncios que ves en función de tu perfil y preferencias.',
					value: true,
					key: 'ad_personalization',
				},
				{
					title: 'Almacenamiento de publicidad',
					description:
						'Estas cookies son utilizadas para mostrarte anuncios relevantes basados en tus intereses.',
					value: true,
					key: 'ad_storage',
				},
				{
					title: 'Almacenamiento de analíticas',
					description:
						'Estas cookies se utilizan para recopilar datos anónimos sobre el uso de nuestro sitio web para mejorar su rendimiento y contenido',
					value: true,
					key: 'analytics_storage',
				},
			],
		}
	},
	watch: {
		configureCookiesDialog(val) {
			if (val) this.showCookiesBanner = false
		},
	},
	computed: {
		enabled() {
			let { gaId, gaConsent } = this.$srv('Analytics') || {}
			return !!(gaId && gaConsent && gaConsent != 'none')
		},
		configureCookiesDialog: sync('shop/cookiesDialog'),
	},
	methods: {
		acceptAllCookies() {
			const data = {}
			for (let option of this.cookiesOptions) {
				if (option.value !== undefined) data[option.key] = true
			}
			this.updateConsent(data)
		},
		saveCookiesOptions() {
			const data = {}
			for (let option of this.cookiesOptions) {
				if (option.value !== undefined) data[option.key] = option.value
			}
			this.updateConsent(data)
		},
		updateConsent(data) {
			window.localStorage.setItem('cookiesOptions', JSON.stringify(data))
			window.gtag('consent', 'update', {
				ad_user_data: data.ad_user_data === true ? 'granted' : 'denied',
				ad_personalization: data.ad_personalization === true ? 'granted' : 'denied',
				ad_storage: data.ad_storage === true ? 'granted' : 'denied',
				analytics_storage: data.analytics_storage === true ? 'granted' : 'denied',
			})
			this.configureCookiesDialog = false
			this.showCookiesBanner = false
		},
	},
	mounted() {
		if (!this.enabled) return

		const cookiesOptions = JSON.parse(window.localStorage.getItem('cookiesOptions') || null)
		if (!cookiesOptions) {
			this.showCookiesBanner = true
		} else {
			for (let option of this.cookiesOptions) {
				if (option.value !== undefined) option.value = !!cookiesOptions[option.key]
			}
		}
	},
}
</script>

<template>
	<div v-if="enabled">
		<div
			v-if="showCookiesBanner"
			class="cookies-disclaimer"
			:class="{ 'cookies-disclaimer--mobile': $b.mt, 'cookies-disclaimer--desktop': $b.d }"
		>
			<div class="cookies-disclaimer__container">
				<div class="cookies-disclaimer__text">Usamos cookies para mejorar tu experiencia.</div>
				<div class="cookies-disclaimer__actions">
					<Button color="success" @click="acceptAllCookies"><b>Entendido</b></Button>
					<Button small text color="success" @click="configureCookiesDialog = true"
						>Configurar cookies
					</Button>
				</div>
			</div>
		</div>
		<v-dialog v-model="configureCookiesDialog" max-width="752px" scrollable>
			<v-card class="cookies-dialog">
				<v-card-title class="d-flex align-center justify-space-between">
					<div>Configurar cookies</div>
					<Button plain @click="configureCookiesDialog = false" icon>
						<v-icon>mdi-close</v-icon>
					</Button>
				</v-card-title>
				<v-card-text style="max-height: 400px">
					<div v-for="(option, n) in cookiesOptions" :key="option.key">
						<div class="d-flex justify-space-between">
							<div class="pr-2">
								<div class="font-2">{{ option.title }}</div>
								<div class="font-1 grey--text text--darken-1">{{ option.description }}</div>
							</div>
							<v-switch v-if="option.value !== undefined" v-model="option.value" color="success" />
						</div>
						<v-divider class="my-3" v-if="cookiesOptions.length - 1 != n" />
					</div>
				</v-card-text>
				<v-card-actions class="justify-end py-md-6 pr-md-6">
					<Button @click="saveCookiesOptions" color="success" large :block="!$b.d" class="px-md-8">
						GUARDAR
					</Button>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style scoped lang="scss">
.cookies-disclaimer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 10px;
	z-index: 1000;
	background-color: white;
	box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);

	&__actions > :first-child {
		margin-right: 10px;
	}

	&__container {
		margin: 0 auto;
	}

	&--mobile {
		text-align: center;

		.cookies-disclaimer__text {
			margin-bottom: 10px;
		}

		.cookies-disclaimer__actions {
			display: flex;
			justify-content: center;
		}
	}
	&--desktop {
		.cookies-disclaimer__container {
			display: flex;
			justify-content: space-around;
			align-items: center;
			max-width: 1200px;
		}
	}
}
</style>

