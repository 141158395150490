<script>
export default {
	props: {
		data: Object,
	},
	hooksKey: 'Footer-Top',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	computed: {
		mappedColumns() {
			if (this.data.columns?.length) {
				return this.data.columns.map((el) => {
					if (el.logo || el.contactInfo || el.socialMedia) {
						el.businessInfo = {
							logo: el.logo,
							urlLogo: el.urlLogo,
							contactInfo: el.contactInfo,
							socialMedia: el.socialMedia,
						}
						return el
					}
					return el
				})
			} else return null
		},
		alignment() {
			if (this.$b.td) {
				switch (this.data.textAlignDesktop) {
					case 'right':
						return 'justify-end text-end'

					case 'center':
						return 'justify-center text-center'

					default:
						return 'justify-start text-start'
				}
			} else {
				switch (this.data.textAlignMobile) {
					case 'right':
						return 'justify-end text-end'

					case 'center':
						return 'justify-center text-center'

					default:
						return 'justify-start text-start'
				}
			}
		},
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>

<template>
	<div class="footer base" v-if="mappedColumns">
		<v-container>
			<div
				class="d-flex"
				:class="[
					{
						'flex-wrap': $b.mt,
					},
					alignment,
				]"
			>
				<div
					:class="
						$b({
							t: item.colType == 'info' || item.colType == 'form' ? 'w100' : 'w25 pa-2 mb-3',
							d: item.colType == 'info' || item.colType == 'form' ? 'w150 pa-2' : 'w100 pa-2',
							m: 'w100',
						})
					"
					v-for="(item, i) of mappedColumns"
					:key="i"
				>
					<!-- BUSINESS INFO -->
					<Footer-BusinessInfo
						v-if="item.colType == 'info'"
						:businessInfo="item.businessInfo"
						:textAlignDesktop="data.textAlignDesktop"
						:textAlignMobile="data.textAlignMobile"
					/>

					<Footer-LinkList :data="item" :textAlign="alignment" v-else-if="item.colType == 'link'" />

					<Footer-CategoriesList :data="item" :textAlign="alignment" v-else-if="item.colType == 'cat'" />

					<Footer-BrandsList :data="item" :textAlign="alignment" v-else-if="item.colType == 'brand'" />

					<div v-else :class="{ 'mb-4': $b.m }">
						<Footer-Newsletter :title="item.title" :alignment="alignment" />
					</div>
				</div>
			</div>
			<hook zone="end" />
		</v-container>
	</div>
</template>

<style scoped lang="scss">
::v-deep .footer-link {
	margin-bottom: 10px;
	& span {
		opacity: 0.8;

		&:hover {
			opacity: 1;
		}
	}
}

.w150 {
	width: 150%;
}

.w25 {
	width: 25%;
}
</style>

