// Pathify
import { make } from 'vuex-pathify'

export default ({ srv }) => {
	const state = {
		user: srv('user', null),
		wishlistIds: srv('wishlistIds', []),
		loginDrawer: false,
		cartDrawer: false,
		menuMobileDrawer: false,
		offersDrawer: false,
		appBarRoundDrawer: false,
		forceTransparency: false,
		upsells: null,
		defaultAppComponent: 'ShopApp',
		appComponent: 'ShopApp',
		loading: false,
		limitedTimeOffer: limitedTimeOffer(srv),
		// limitedTimeOffer: srv('limitedTimeOffer', []),
		limitedTimeOfferCountdown: false,
		loginLoading: false,
		googleLoginLoading: false,
		routeLoading: false,
		navbarHeight: 0,
		showWhatsappChat: false,
		ctaIsLoading: false,
		brandsDialog: false,
		cookiesDialog: false,
	}

	const mutations = {
		...make.mutations(state),
		SET_LOGIN_DRAWER: (state, value) => {
			state.loginDrawer = value
			state.cartDrawer = false
			state.menuMobileDrawer = false
			state.offersDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
		SET_CART_DRAWER: (state, value) => {
			state.cartDrawer = value
			state.loginDrawer = false
			state.menuMobileDrawer = false
			state.offersDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
		SET_MENU_MOBILE_DRAWER: (state, value) => {
			state.menuMobileDrawer = value
			state.loginDrawer = false
			state.cartDrawer = false
			state.offersDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
		SET_OFFER_DRAWER: (state, value) => {
			state.offersDrawer = value
			state.loginDrawer = false
			state.cartDrawer = false
			state.menuMobileDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
	}

	const actions = make.actions(state)

	const getters = {
		...make.getters(state),
	}

	return {
		name: 'shop',
		namespaced: true,
		state,
		mutations,
		actions,
		getters,
	}
}

const limitedTimeOffer = (srv) => {
	const res = srv('limitedTimeOffer', [])

	if (!res.length) return null

	const timerUnits = {
		day: 86400,
		hour: 3600,
		minute: 60,
		second: 1,
	}

	return res.map((r) => {
		const { secondsLeft, collectionId, untilDate } = r

		let days = secondsLeft / timerUnits['day']
		let hours = (secondsLeft % timerUnits['day']) / timerUnits['hour']
		let minutes = (secondsLeft % timerUnits['hour']) / timerUnits['minute']
		let seconds = (secondsLeft % timerUnits['minute']) / timerUnits['second']

		return {
			days: Math.floor(days),
			hours: Math.floor(hours),
			minutes: Math.floor(minutes),
			seconds: Math.floor(seconds),
			secondsLeft,
			collectionId,
			untilDate,
		}
	})
}

