<script>
import { sync } from 'vuex-pathify'
import whatsappConfigMixin from '#/v-shop/vue-src/v-shop/mixins/whatsapp-config-mixin.js'

export default {
	mixins: [whatsappConfigMixin],
	data() {
		return {
			showMenu: false,
		}
	},
	computed: {
		showWhatsappChat: sync('shop/showWhatsappChat'),
	},
}
</script>

<template>
	<div>
		<UrlMatch v-for="(data, n) in whatsAppConfig" :key="n" :data="data">
			<DeviceMatch :data="data" v-if="data">
				<div>
					<transition name="fade">
						<div
							v-if="!showWhatsappChat"
							v-ripple
							class="whatsapp-btn"
							:class="[
								data.position,
								{
									'whatsapp-btn--round':
										!data.btnTitle && !data.btnTextAboveTitle && !data.showOnlineStatus,
								},
							]"
							@click="showWhatsappChat = true"
						>
							<v-icon class="whatsapp-btn__icon" color="white" x-large>mdi-whatsapp</v-icon>
							<div
								v-if="data.btnTitle || data.btnTextAboveTitle || data.showOnlineStatus"
								class="whatsapp-btn__text-cont"
							>
								<div
									v-if="data.btnTextAboveTitle || data.showOnlineStatus"
									class="whatsapp-btn__title-status-cont"
								>
									<div v-if="data.btnTextAboveTitle" class="whatsapp-btn__title font-1">
										{{ data.btnTextAboveTitle }}
									</div>
									<div
										v-if="data.showOnlineStatus"
										class="whatsapp-btn__status font-0 font-weight-bold"
									>
										Online
									</div>
								</div>
								<div v-if="data.btnTitle" class="whatsapp-btn__subtitle font-2">
									{{ data.btnTitle }}
								</div>
							</div>
						</div>
					</transition>
				</div>
			</DeviceMatch>
		</UrlMatch>
	</div>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

::v-deep .round-img {
	border-radius: 50%;
}

.whatsapp-btn {
	position: fixed;
	z-index: 3;
	bottom: 10px;
	cursor: pointer;
	background: #2db642;
	color: white;
	display: flex;
	align-items: center;
	padding: 8px 16px 8px 16px;
	border-radius: 30px;

	&--round {
		border-radius: 50%;
		padding: 12px;
	}

	&__text-cont {
		padding-right: 16px;
		padding-left: 8px;
	}

	&__title-status-cont {
		display: flex;
		align-items: center;
	}

	&__status {
		background: #5fc369;
		color: #b8edc0;
		margin: 0 2px 0 2px;
		padding: 0 6px 0 6px;
		border-radius: 6px;
		line-height: 1.4;
	}
	@media (max-width: 900px) {
		bottom: 80px;
	}
}

.left {
	left: 20px;
}

.right {
	right: 20px;
}
</style>

