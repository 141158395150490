<script>
import appbarMixin from '@/v-shop/appbarMixin'
import { sync } from 'vuex-pathify'

export default {
	mixins: [appbarMixin],
	provide() {
		return {
			appbar: this,
		}
	},
	data() {
		return {
			showSearch: false,
			searchFieldAlwaysVisible: this.$srv('AppBarConfig.searchFieldAlwaysVisible'),
			showHorizontalMegaMenu: false,
			megaMenuShowing: false,
			forceHover: 1,
		}
	},
	computed: {
		appBarRoundDrawer: sync('shop/appBarRoundDrawer'),
		containerClass() {
			if (!this.showMegaMenu || this.roundAppBarMenuLocation === 'after') return 'px-6'
			return 'pr-6'
		},
	},
	methods: {
		toggleSearch() {
			this.showSearch = !this.showSearch
			// if (!this.showSearch) this.$refs.searchField?.blur()
			setTimeout(() => {
				if (this.showSearch) this.$refs.searchField?.focus()
			}, 500)
		},
	},
	watch: {
		$route() {
			this.showSearch = false
		},
	},
}
</script>

<template>
	<div
		id="appbar-menu"
		class="d-flex justify-center h100"
		style="position: relative"
		:class="containerClass"
	>
		<div v-if="roundAppBarMenuLocation === 'before' && showMegaMenu" class="d-flex align-center">
			<CategoriesMegaMenu
				v-if="megaMenuType === 'vertical'"
				noBg
				dialog-arrow
				hamburgerBtn
				:btnText="config.megaMenuBtnText"
				:overlay="false"
				:menuStyles="{ left: '5px', top: '70px' }"
				:submenuStyles="'width: 965px'"
			/>
			<Hover
				v-if="megaMenuType === 'horizontal' && $b.lgAndUp"
				@updateModel="showHorizontalMegaMenu = $event"
				open-delay="250"
				close-delay="250"
			>
				<Button
					class="base"
					:class="{ 'dialog-arrow': showHorizontalMegaMenu || megaMenuShowing }"
					style="background: transparent !important"
					large
					tile
					depressed
				>
					<v-icon
						class="mx-0"
						:size="30"
						:class="{ 'icon-rotate': showHorizontalMegaMenu || megaMenuShowing }"
						>mdi-menu</v-icon
					>
					<span v-if="config.megaMenuBtnText" class="font-1 font-weight-bold">
						{{ config.megaMenuBtnText }}
					</span>
				</Button>
			</Hover>
		</div>

		<SafeLink class="d-flex justify-center align-center" to="/">
			<Media :src="$b({ d: logo, mt: logoMobile })" />
		</SafeLink>
		<div v-if="showMegaMenu && roundAppBarMenuLocation === 'after'" class="d-flex align-center">
			<CategoriesMegaMenu
				v-if="megaMenuType === 'vertical'"
				noBg
				hamburgerBtn
				dialog-arrow
				:btnText="config.megaMenuBtnText"
				:overlay="false"
				:menuStyles="{ left: '5px', top: '70px' }"
				:submenuStyles="'width: 770px'"
				submenuClass="rounded"
			/>
			<Hover
				@updateModel="showHorizontalMegaMenu = $event"
				open-delay="250"
				close-delay="250"
				v-if="megaMenuType === 'horizontal' && $b.lgAndUp"
			>
				<Button
					class="base"
					:class="{ 'dialog-arrow': showHorizontalMegaMenu || megaMenuShowing }"
					style="background: transparent !important"
					large
					tile
					depressed
				>
					<v-icon
						class="mx-0"
						:size="30"
						:class="{ 'icon-rotate': showHorizontalMegaMenu || megaMenuShowing }"
						>mdi-menu</v-icon
					>
					<span v-if="config.megaMenuBtnText" class="font-1 font-weight-bold">
						{{ config.megaMenuBtnText }}
					</span>
				</Button>
			</Hover>
		</div>

		<Hover
			unhover-on-route-loading
			@updateModel="megaMenuShowing = $event"
			v-slot="{ hover }"
			close-delay="250"
			v-if="megaMenuType === 'horizontal' && $b.lgAndUp"
		>
			<div
				v-show="showHorizontalMegaMenu || hover"
				style="position: absolute; top: 75px; left: 0; max-width: 1200px; width: 100%"
			>
				<CategoriesMegaMenuHorizontal
					:overlay="false"
					class="justify-start pl-3 base rounded rounded-b-0 dialog-arrow elevation-11"
					submenuClass="rounded-t-0 shadow"
					no-overlay
					v-model="forceHover"
					:submenuStyle="{ 'max-width': '1200px' }"
				/>
			</div>
		</Hover>

		<div class="center-container">
			<transition name="slide-fade" mode="out-in">
				<div
					key="search"
					class="d-flex align-center justify-center h100"
					v-if="showSearch || searchFieldAlwaysVisible"
				>
					<div class="search-field-container">
						<AutocompleteSearchField
							ref="searchField"
							id="searchField"
							round
							:noIcon="!searchFieldAlwaysVisible"
							@visible:change="showSearch = $event"
							class="fixedHeight"
						/>
					</div>
				</div>

				<div v-else key="items" class="d-flex align-center h100">
					<AppBar-Bottom-CategoriesMenu />

					<AppBar-Bottom-MenuItems
						dense
						no-bg-item
						hide-icons
						class="d-flex flex-wrap align-center h100"
					/>
				</div>
			</transition>
		</div>
		<div class="d-flex py-4">
			<Button icon @mousedown="toggleSearch" v-if="!searchFieldAlwaysVisible">
				<v-icon :color="$vars.basetext" size="30" class="icon">mdi-magnify</v-icon>
			</Button>
			<AppBar-Buttons dense />
		</div>
	</div>
</template>

<style lang="scss" scoped>
::v-deep .v-btn,
::v-deep .v-btn::before,
::v-deep .v-btn:hover::before {
	background: transparent !important;
	border-radius: 17px;
}

.search-field-container {
	//position: absolute;
	z-index: 1;
	width: 90%;
}

.icon-rotate {
	transform: rotate(90deg);
}

::v-deep .shadow {
	box-shadow: 0px 20px 9px 0px rgba(0, 0, 0, 0.2), 0px 20px 17px 2px rgba(0, 0, 0, 0.14),
		1px 20px 22px 4px rgba(0, 0, 0, 0.12) !important;
}

.fixedHeight {
	height: 40px;
	vertical-align: middle;
}

.center-container {
	width: 100%;
}

.btn,
::v-deep .btn {
	color: var(--basetext) !important;
}

.slide-fade-enter-active {
	transition: all 0.165s ease;
}

.slide-fade-leave-active {
	transition: all 0.165s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
	transform: translateX(10px);
	opacity: 0;
}

.dialog-arrow::after {
	content: '';
	width: 0;
	height: 0;
	border: 0 solid transparent;
	border-right-width: 14px;
	border-left-width: 14px;
	border-bottom: 14px solid var(--base);
	top: 51px;
	left: 20px;
	position: absolute;
	display: block;
}

.arrow-offset-left::after {
	left: 7px;
}
</style>

